import React, { Component } from "react";
import PropTypes from "prop-types";

class GoogleAdConversionTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      triggeredAsyncConversion: false,
      // renderedImage: false,
      renderedScript: false
    };
    this.GOOGLE_CONVERSION_SCRIPT =
      "//www.googleadservices.com/pagead/conversion.js";
    this.GOOGLE_CONVERSION_IMG =
      "//www.googleadservices.com/pagead/conversion/";
  }

  componentDidMount() {
    // this.injectTrackingScript();
    this.triggerAsyncConversion();
  }

  triggerAsyncConversion() {
    if (!this.state.triggeredAsyncConversion) {
      const conversionObject = {
        google_conversion_language: "en",
        google_conversion_format: "3",
        google_conversion_color: "ffffff"
      };
      conversionObject.google_remarketing_only = this.props.remarketingOnly;
      conversionObject.google_conversion_label = this.props.conversionLabel;
      conversionObject.google_conversion_id = this.props.conversionId;

      if (this.props.conversionValue) {
        conversionObject.google_conversion_value = this.props.conversionValue;
        conversionObject.google_conversion_currency = this.props.conversionValue;
      }

      if (window.google_trackConversion !== undefined) {
        window.google_trackConversion(conversionObject);
      }
      this.setState({ triggeredAsyncConversion: true });
    }
  }

  injectTrackingScript() {
    if (!this.state.renderedScript) {
      const script = document.createElement("script");
      script.addEventListener("load", () => {
        this.props.onSuccess();
      });
      script.addEventListener("error", e => {
        this.props.onError(e);
      });
      window.google_conversion_id = this.props.conversionId;
      window.google_conversion_label = this.props.conversionLabel;
      window.google_remarking_only = this.props.remarketingOnly;
      if (this.props.conversionValue) {
        window.google_conversion_value = this.props.conversionValue;
        window.google_conversion_currency = this.props.conversionValue;
      }
      script.async = true;
      script.src = this.GOOGLE_CONVERSION_SCRIPT;

      document.body.appendChild(script);
      this.setState({ renderedScript: true });
    }
  }

  injectTrackingImage() {
    let src = `${this.GOOGLE_CONVERSION_IMG}${this.props.conversionId}?label=${this.props.conversionLabel}&amp;guid=ON&amp;script=0`;
    if (this.props.conversionValue) {
      src += `&amp;value=${this.props.conversionValue}&amp;currency_code=${this.props.conversionCurrency}`;
    }

    return (
      <img src={src} style={{ border: "none", borderStyle: "none" }} alt="" />
    );
  }

  render() {
    return this.injectTrackingImage();
  }
}

GoogleAdConversionTracking.defaultProps = {
  conversionId: 1045250664,
  remarketingOnly: false,
  onSuccess: () => {},
  onError: error => console.error("Could Not Inject G Conversion", error),
  conversionCurrency: "USD",
  conversionValue: null
};

GoogleAdConversionTracking.propTypes = {
  conversionId: PropTypes.number,
  conversionLabel: PropTypes.string,
  remarketingOnly: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  conversionCurrency: PropTypes.string,
  conversionValue: PropTypes.number
};

export default GoogleAdConversionTracking;
