import React, {useState} from "react";
import { observer } from "mobx-react";
import useStore from "../hooks/useStore";
import {ADWORDS_LABELS, SEARCH_TYPES} from "../helpers";
import { FullSearchForm, TicketIdSearchForm } from "../components/";
import GoogleConversionTracking from "../tracking/googleAdConversion"

const RenderSearchForm = () => {
  const { search , general} = useStore();
  const [conversionTriggered, setConversionTriggered] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setConversionTriggered(true);
    return search.attemptSearch(SEARCH_TYPES.full);
  };

  const handleChange = e => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    return search.handleSearchChange(fieldName, fieldValue);
  };

  const props = {
    handleChange,
    handleSubmit,
    disabled: search.fetching
  };
  let SearchForm = FullSearchForm;
  switch (search.searchType) {
    default:
    case SEARCH_TYPES.full:
      props.lastName = search.lastName;
      props.plateNumber = search.plateNumber;
      props.plateState = search.plateState;
      SearchForm = FullSearchForm;
      break;
    case SEARCH_TYPES.ticket:
      props.ticketId = search.ticketId;
      SearchForm = TicketIdSearchForm;
      break;
  }
  const city = general.location.name.toLowerCase()
  return (
    <>
    <SearchForm {...props} />
      {conversionTriggered && (
        <GoogleConversionTracking
          onSuccess={() => {}}
          onError={err => console.error("GC", err)}
          remarketingOnly={false}
          conversionLabel={ADWORDS_LABELS[city].search}
        />
      )}
    </>
  );
};

export default observer(RenderSearchForm);
